import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import '../About.css';

function NewUpdate(){
    return(
        <div>
            <Navbar/>
            <div>
                <div className='about-title'>
                <div className='container'>
                <h1 className='text-bold fw-bold'>New Update</h1>

               
            </div>


                </div>

            
                <div className='container corporate-value'>
                    <h2 className='about-infinity-title'></h2>

                    <h3 className='description'>
                        No Update Found.
                   </h3>


                </div>

            </div>
          
            <Footer/>
            </div>
        
    )
}

export default  NewUpdate;