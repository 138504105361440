import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import '../BangladeshProfile.css';

function BangladeshProfile(){
    return(
        <div>
            <Navbar/>
            <div>
                <div className='bangladesh-profile-title'>
                 <div className='container'>
                   <h1 className='text-bold fw-bold'>Bangladesh Profile</h1>
                 </div>
                </div>

            
                <div className='container bprofile-body'>
                    <h5 className='bprofile-title'>BANGLADESH at a glance:</h5>

                    <p className='bprofile-description'>
                    Banglaesh`s remains one of the world`s most densely populated nations.

It is an agro based economy and with the passage of time, the Country is emerging with a phenomenal industrial growth.

Bangladesh can boast of 6.3 % growth rate in the last fiscal year.

The current projection of growth is 7.5 % for the 2013 � 2014 fiscal year.
                    </p>

                    <h5 className='bprofile-title'>BANGLADESH COUNTRY PROFILE:</h5>
                    <p className='bprofile-description'>
                    �      Total Area (Sq Km)          :: 1,47,570<br/>

                    �      Capital                     :: Dhaka<br/>

                    �      Other Main Cities           :: Chittagong, Khulna, Rajshahi<br/>

                    �      Official Languages          :: Bangla (Bengali)<br/>

                    �      National Currency           :: Taka (TK)<br/>

                    �      Population                  :: 151.10 million<br/>

                    �      Weekly Holidays             :: Friday & Saturday
                    </p>

                    <h5 className='bprofile-main-port'>MAIN SEA PORTS :</h5>
                    <p className='bprofile-description'>
                    Chittagong Port Authority ( CPA )<br/>

                    Mongla Port Authority ( MPA )<br/>

                    Inland Container Depot ( ICD ) Dhaka <br/>
                    </p>

                   <h6 className='port_sub_title'>
                   Chittagong Port Authority ( CPA ):
                   </h6>

                   <p className='prot-description'>
                   Chittagong port is the major seaport of Bangladesh. It provides a major gateway for the country`s trade with outside world. This port handles around 92 % of total maritime trade of Bangladesh. It is a tidal port.
                   </p>

                   <p className='port_sub_title'>
                   Official Name : Chittagong Port Authority ( CPA )
                   </p>
                   <p className='port_sub_title'>
                   Website Name : <a href="http://www.cpa.gov.bd">www.cpa.gov.bd</a>
                   </p>
                   <p className='port_sub_title-with-des'>
                   Chittagong port is situated in the estuary of the river Karnaphuli, Being around eight nautical miles inshore from the port`s outer ancharage.
                   </p>
                   <p className='port_sub_title'>
                    Facilities :
                   </p>
                   <p className='port_sub_sub_title'>General Cargo Berth {'\u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0'} 06 Nos.</p>
                   <p className='port_sub_sub_title'>Container Berths   {'\u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0'} 11 Nos.</p>
                   <p className='port_sub_sub_title'>( 5 berth equipped with gantry crane )<br/>
                   
                   Specialized Berth for Bulk Handling {' \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0'} 07 Nos</p>
                   <p className='port_sub_sub_title'>Repair Berth     {' \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0'} 02 Nos.</p>
                   <p className='port_sub_sub_title'>River Mooring       {'\u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0'} 05 Nos.</p>
                
                   <h5 className='bprofile-main-port'>General Cargo Handling Facilities :</h5>
                   <p className='port_sub_title'>
                   Storage Space :
                   </p>
                   <p className='port_sub_sub_title'>Transit Shed       {' \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0'} 64,365 Sqm.</p>
                   <p className='port_sub_sub_title'>Warehouse        {'\u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0'}   45,891 Sqm.</p>
                   <p className='port_sub_sub_title'>Open Dumps          {'\u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0'}  290,000 Sqm.</p>
                
                   <p className='port_sub_title'>
                   Cargo Handing Equipment :
                   </p>
                   <p className='port_sub_sub_title'>Mobile Crane       {' \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0'} 31 Nos.</p>
                   <p className='port_sub_sub_title'>Forklift Truck        {'\u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0'} 84 Nos.</p>
                   <p className='port_sub_sub_title'>Industrial Tractor           {'\u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0'}  14 Nos.</p>
                   <p className='port_sub_sub_title'>Heavy Trailer         {' \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0'} 02 Nos.</p>
                   <p className='port_sub_sub_title'>Light Trailer             {'\u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0'} 30 Nos.</p>
          
          
                   <h5 className='bprofile-main-port'>Container Handling Facilities :</h5>
                   <p className='port_sub_sub_title'>Holding Capacity (GCB+CCT+NCT+NCY)      {'\u00A0 \u00A0  \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0'} 32017 TEUs.</p>
                   <p className='port_sub_sub_title'>Yard        {' \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0'} 20 Nos.</p>
                   <p className='port_sub_sub_title'>Container Freight Station          {'\u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0'}  45064 sq.m.</p>
                   <p className='port_sub_sub_title'>CFS at CCT         {'\u00A0  \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0'} 13671 sq.m.</p>
                   <p className='port_sub_sub_title'>Container Storage Yard (GCB+CCT+NCT+NCY)              {' \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0'}  672803 sq.m.</p>
                   <p className='port_sub_sub_title'>Railway Container siding       {'\u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0'}550 m.</p>
                   <p className='port_sub_sub_title'>Reefer Points         {'\u00A0  \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0'}   900 (415 volts).</p>
                   <p className='port_sub_sub_title'>Stand by Generator             {'\u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0'}   08 mw.</p>
                 
                                               

                   <h5 className='bprofile-main-port'>Container Handling Equipment :</h5>
                   <p className='port_sub_sub_title'>Quay Gantry Crane      {'\u00A0 \u00A0 \u00A0 \u00A0  \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0  \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0  \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0'}4 Nos.</p>
                   <p className='port_sub_sub_title'>Rubber Tyred Gantry Crane  {'\u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0'} 32 Nos.</p>
                   <p className='port_sub_sub_title'>Straddle Carrier       {'\u00A0 \u00A0  \u00A0 \u00A0 \u00A0 \u00A0 \u00A0  \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0'}  31 Nos.</p>
                   <p className='port_sub_sub_title'>Reach Stacker        {'\u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0'} 16 Nos.</p>
                   <p className='port_sub_sub_title'>Forklift Truck       {' \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0'} 24 Nos.</p>
                   <p className='port_sub_sub_title'>Container Mover                {' \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0'}  06 Nos.</p>
                   <p className='port_sub_sub_title'>Terminal Tractor       {'\u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0'}43 Nos.</p>
                   <p className='port_sub_sub_title'>Trailer        {'\u00A0  \u00A0  \u00A0 \u00A0 \u00A0  \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0'} 55 Nos.</p>
                  
              
                   <h5 className='bprofile-main-port'>Navigation Information :</h5>
                   <p className='port_sub_sub_title'>Permissible Draught      {'\u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0  \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0  \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0'}8.50 M to 9.20 M.</p>
                   <p className='port_sub_sub_title'>Permissible LOA  {'\u00A0 \u00A0  \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0'} 186 M.</p>
                   <p className='port_sub_sub_title'>Tidal Range       {'\u00A0 \u00A0 \u00A0  \u00A0 \u00A0 \u00A0 \u00A0 \u00A0  \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0'}  2.0 to 5.5 M.</p>
                   <p className='port_sub_sub_title'>Pilotage        {'\u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0'} Compulsory.</p>
                   <p className='port_sub_sub_title'>Radio Communication       {'\u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0'}  VHF Channel 12 & 16.</p>
                   <p className='port_sub_sub_title'>Night Navigation               {' \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0'}  LOA limited to 153 M.</p>
                 
              
                   <h5 className='bprofile-title'> MONGLA PORT:</h5>
                   <p className='port_sub_title'>
                   MONGLA PORT:
                   </p>
                   <p className='prot-description'>
                   Mongla is in a unique position to handle export and import traffic of the country as well as the transit trade of neighboring countries.

                    </p>
                    <p className='port_sub_title'>
                    Official Name : Mongla Port Authority ( MPA )
                   </p>
                   <p className='port_sub_title'>
                   Website Name : <a href="http://www.mpa.gov.bd">www.mpa.gov.bd</a>
                   </p>
                   <p className='port_sub_title-with-des'>
                   Mongla port is located near the confluence of Pussur Rivers and Mongla Nulla at a distance of 71 Nautical Miles from the Fairway  Buoy situated in the Bay of Bengal.
                    </p>

                    <p className='port_sub_title'>
                    Function Of MPA:
                   </p>
                   <p className='prot-description'>
                   Mongla Port is basically a service as well as income oriented organization. The port provides facilities and services to the international shipping lines and other concerned agencies providing shore based facilities like jetties, godowns, cargo handling equipment and maintaining adeuate water depth in the channel as well as making provision for safe day and night shipping. </p>
                 
                   <p className='port_sub_title'>
                   Geographical Position of Mongla Port:
                   </p>
                   <p className='prot-description'>
                                    Mongla Port is situated on the East Bank of Pussur River near the confluence of Pussur River and Mongla Nulla at Channel distance of 71 Nautical Miles from the Fairway Buoy situated (Lat. 21026.9` N. long. 890 34.4 E) in the Bay of Bengal.

                    Port Limit:To the North:  A line drawn due East West from a point in position Lat.  220 38.00` N.long 89020.00 E. to a point in position lat. 22038.00 N. long 89040.00` E.

                    To the South: A line drawn due East West from a point in position Lat.21027.00`N Long 89020.00 E. to a point in position lat. 21027.00 N. long 89040.00` E.

                    To the East:   A line joining the point in position Lat. 21027.00 N. long

                    89040.00` E. with the point in position Lat. 22038.00� N. long 89040.00` E.

                    To the West:  A line joining the point in position Lat. 22038.00 N. long

                    89020.00` E. with the point in position lat. 21027.00 N. long 89020.00` E

                    Navigational Information:

                    Vessels of 6.0 m. to 8.5 m. draught can take berth in anchorage, mooring buoys and jetties.

                    Maximum LOA of vessels, 200 metre

                    Tidal Range-between 1.2 and 3.5 metre

                    Pilotage-Compulsory

                    Radio Communication-Port control, Harbour Control and Hiron Point Pilot Station maintains VHF channel 16 and 11 for 24 hrs. Three tugs and one fire fighting tug available Night navigation is permissible for outward and inward movement of ships up to 140m. LOA and 6.0 m. draught.

                   </p>

                   <p className='port_sub_title'>
                   Berthing facilities: 
                   </p>

                   <p className='prot-description'>
                   2 ships at the jetty, 5 ships at mooring buoy and 7 ships at anchorage and including private jetties total 14 ships can take berth at a time. Besides there is wide scope of berthing of more ships in the anchorage. 

Facilities in the Channel: Channel draft is 6.0 to 8.5 meter. For safe guidance of ship in the channel there are 21 lighted buoys (inner channel-3 buoys, outer anchorage- 16 buoys and fairway buoy-1) and 1 Beacon (at Hironpont). Max. Length of 200 meter ships can cross the Pussur channel. 

Jetty/Warehouse/Yard facilities: 5 jetties (length 182.92 meter each), 4 transit sheds (4907 Sqm. each & total capacity in average  17932 M.ton), 2 warehouses (9815 Sqm.) each & total capacity in avr.15326 M.ton),120 refer plug points and 3 container yards (35752 TEUS at one stack).
                   </p>

                   <p className='port_sub_title'>

                   Inland Container Depot ( ICD ) DHAKA : 
                   </p>

                   <p className='prot-description'>
                   Container Service in Bangladesh Railway was introduced in 1987 with an annual handling capacity of 90,000 TEUs.

The responsibility of transportation of containers between Chittagong Port and Dhaka ICD rests with Bangladesh Railway whereas management and operation of the ICD remains with Chittagong Port Authority.

Location: Located near Kamalapur Railway Station, Dhaka , 320 kilometers away from Chittagong Port.</p>

                 <p className='port_sub_title'>
                 Facilities Available at ICD :
                 </p>
                   <p className='port_sub_sub_title'>Container holding capacity       {' \u00A0 \u00A0 \u00A0 \u00A0 \u00A0  \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0'}4067 TEUs.</p>
                   <p className='port_sub_sub_title'>Yard Area    {'\u00A0 \u00A0  \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0'} 136954 Sq.m.</p>
                   <p className='port_sub_sub_title'>Container Freight Station        {' \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0'} 8182 Sq.m.</p>
                
                
                   <p className='port_sub_title'>Equipment :</p>

                   <p className='port_sub_sub_title'>  Reach Stacker       {'\u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0  \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0  \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0'}7 Nos.</p>
                   <p className='port_sub_sub_title'> Low Mast Forklift     {'\u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0'} 6 Nos.</p>
                   <p className='port_sub_sub_title'>Tractor Trailers            {'  \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0'} 8 Nos.</p>
                   <p className='bprofile-description'>
                   Cost matrix based container stuffed / de-stuffed at customer premises.

Below charges will be added in case of container<br/>

stuffed / de-stuffed at ICD :<br/>

20` � Usd69.28<br/>

40` � Usd103.92<br/>

4hc � Usd155.88<br/>

VAT @ 15 % applicable on above tariff.
                   </p>

                   <p className='port_sub_title'>
                   Private inland Depot (Offdock):
                   </p>

                   <table class="table">
    <thead>
      <tr>
        <th>Code</th>
       
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>214IEPZ</td>
       
      </tr>
      <tr>
        <td>214IEPZ</td>
       
      </tr>
      <tr>
        <td>301BMCD</td>
       
      </tr>
      <tr>
        <td>301CCTC</td>
       
      </tr>
      <tr>
        <td>301EALL</td>
       
      </tr>
      <tr>
        <td>301EBIL</td>
       
      </tr>
      <tr>
        <td>301GLCL</td>
       
      </tr>
      <tr>
        <td>301HSAT</td>
       
      </tr>
      <tr>
        <td>301INCL</td>
       
      </tr>
      <tr>
        <td>301IQLE</td>
        
      </tr>
      <tr>
        <td>301KDSL</td>
       
      </tr>
      <tr>
        <td>301KEPZ</td>
       
      </tr>
      <tr>
        <td>301KNTL</td>
       
      </tr>
      <tr>
        <td>301NMCL</td>
       
      </tr>
      <tr>
        <td>301OCCL</td>
       
      </tr>
      <tr>
        <td>301PLCL</td>
       
      </tr>
      <tr>
        <td>301QNSC</td>
       
      </tr>
    </tbody>
  </table>

                </div>

            </div>
          
            <Footer/>
            </div>
        
    )
}

export default  BangladeshProfile;