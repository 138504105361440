import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import '../About.css';

function About(){
    return(
        <div>
            <Navbar/>
            <div>
                <div className='about-title'>
                <div className='container'>
                <h1 className='text-bold fw-bold'>About Us</h1>

               
            </div>


                </div>

            
                <div className='container about-body'>
                    <h2 className='about-infinity-title'>About Infinite</h2>

                    <p className='description'>
                    Infinite is focused on providing world class logistics service at a level always exceeding our competitors. We make an extra effort to serve our clients, to get to know them well and meet their needs and requirements. We will offer our clients not just a reactive service but also a proactive approach by using our knowledge to not only provide excellent service, but also to help guide our clients in the most effective direction as it pertains to transport logistics. Infinite will always be committed to excellence, which can only be measured by the satisfaction of our clients.
                    </p>


                </div>

            </div>
          
            <Footer/>
            </div>
        
    )
}

export default  About;