import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import '../About.css';
import caroThree from '../images/caro_three.png'
import caroFour from '../images/caro_four.jpeg'
import caroFive from '../images/caro_five.jpeg'

function PhotoGellery(){
    return(
        <div>
            <Navbar/>
            <div>
                <div className='about-title'>
                <div className='container'>
                <h1 className='text-bold fw-bold'>Our PhotoGellery</h1>

               
            </div>


                </div>

            
                <div className='container corporate-value'>
                   <div className='row'>
                   <div className='col-md-4'>
                 <img src={caroThree} style ={{widt:"249px", height:"248px"}} alt="..."/>

                 </div>
                 <div className='col-md-4'>
                 <img src={caroFour} style ={{widt:"249px", height:"248px"}} alt="..."/>

                </div>
                <div className='col-md-4'>
                <img src={caroFive} style ={{widt:"249px", height:"248px"}} alt="..."/>

                </div>

                   </div>

                   <div className='row'>
                   <div className='col-md-4'>
                 <img src={caroThree} style ={{widt:"249px", height:"248px"}}alt="..."/>

                 </div>
                 <div className='col-md-4'>
                 <img src={caroFour} style ={{widt:"249px", height:"248px"}} alt="..."/>

                </div>
                <div className='col-md-4'>
                <img src={caroFive} style ={{widt:"249px", height:"248px"}} alt="..."/>

                </div>

                   </div>

                </div>

            </div>
          
            <Footer/>
            </div>
        
    )
}

export default  PhotoGellery;