import Carousel from 'react-bootstrap/Carousel';
import caroOne from '../images/caro_one.jpeg'
import caroTwo from '../images/caro_two.jpeg'
import caroThree from '../images/caro_three.png'
import caroFour from '../images/caro_four.jpeg'
import caroFive from '../images/caro_five.jpeg'
import "./Carousel.css"

function UnCarousel() {
  return (
    
    <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
  <div className="carousel-inner">
    <div className="carousel-item active">
      <img src={caroOne} className="d-block w-100" alt="..."/>
    </div>
    <div className="carousel-item">
      <img src={caroTwo} className="d-block w-100" alt="..."/>
    </div>
    <div className="carousel-item">
      <img src={caroThree} className="d-block w-100" alt="..."/>
    </div>
    <div className="carousel-item">
      <img src={caroFour} className="d-block w-100" alt="..."/>
    </div>
    <div className="carousel-item">
      <img src={caroFive} className="d-block w-100" alt="..."/>
    </div>
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>
  );
}

export default UnCarousel;