import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import '../BoardOfDirectors.css';


function OurConcern(){
    return(
        <div>
            <Navbar/>
            <div>
                <div className='about-title'>
                <div className='container'>
                <h1 className='text-bold fw-bold'>Our Concerns</h1>

               
            </div>


                </div>

            
                <div className='container about-body'>
                    <h2 className='about-infinity-title'>Our Subsidiary/Affiliated Companies:</h2>

                    <h6 className='board-director-one'>
                    INFINITE SHIPPING & LOGISTICS LTD.
                    </h6>
                    <h6 className='about-descrption'>
                    TBA CARGO SERVICES LTD.
                   </h6>
                   <h6 className='board-director-two'>
                    M/S. FARUK IMPEX
                   </h6>


                </div>

            </div>
          
            <Footer/>
            </div>
        
    )
}

export default  OurConcern;