
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import { Route, Routes } from 'react-router';
import Layout from './pages/Layout';
import Home from './pages/Home';
import About from './pages/About';
import MdMessage from './pages/MdMessage';
import MissionAndVision from './pages/MissionVission';
import CorporateValue from './pages/CorporateValue';
import BoardOfDirectors from './pages/BoardOfDirectors';
import Activities from './pages/Activities';
import SocialResponsibility from './pages/SocialResponsibility';
import BangladeshProfile from './pages/BangladeshProfile';
import OurConcern from './pages/OurConcern';
import OurProducts from './pages/OurProducts';
import GlobalMarkets from './pages/GlobalMarkets';
import OurServices from './pages/Services';
import UsefullLink from './pages/UsefulLink';
import PhotoGellery from './pages/PhotoGellery';
import ContactUs from './pages/ContactUs';
import Carrer from './pages/Carrear';
import NoticeBoard from './pages/NoticeBoard';
import NewUpdate from './pages/NewUpdate';
import Donwload from './pages/ImprotantDownload';
import Sitemap from './pages/Sitemap';


function App() {
  return (
    <BrowserRouter>
      <Routes>
         <Route path='/' element = {<Layout/>}>
         <Route index element = {<Home/>}/>
         <Route path= "/about" element = {<About/>}/>
         <Route path='/message' element = {<MdMessage/>}/>
         <Route path='/mission' element = {<MissionAndVision/>}/>
         <Route path='/corporate' element = {<CorporateValue/>}/>
         <Route path='/board-directors' element = {<BoardOfDirectors/>}/>
         <Route path='/activities' element = {<Activities/>}/>
         <Route path='/social-responsibility' element = {<SocialResponsibility/>}/>
         <Route path='/bangladesh-profile' element = {<BangladeshProfile/>}/>
         <Route path='/our-concern' element = {<OurConcern/>}/>
         <Route path='/our-products' element = {<OurProducts/>}/>
         <Route path='/global-markets' element = {<GlobalMarkets/>}/>
         <Route path='/services' element = {<OurServices/>}/>
         <Route path='/useful-link' element = {<UsefullLink/>}/>
         <Route path='/photogelley' element = {<PhotoGellery/>}/>
         <Route path='/contact-us' element = {<ContactUs/>}/>
         <Route path='/carrer' element = {<Carrer/>}/>
         <Route path='/notice-board' element = {<NoticeBoard/>}/>
         <Route path='/new-update' element = {<NewUpdate/>}/>
         <Route path='/download' element = {<Donwload/>}/>
         <Route path='/sitemap' element = {<Sitemap/>}/>

         </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;