import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import '../About.css';

function SocialResponsibility(){
    return(
        <div>
            <Navbar/>
            <div>
                <div className='about-title'>
                <div className='container'>
                <h1 className='text-bold fw-bold'>Our Clients</h1>

               
            </div>


                </div>

            
                <div className='container corporate-value'>
                <div className='container about-body'>
                    <h2 className='about-infinity-title'>Our Local Clients:</h2>

                    <h6 className='board-director-one'>
                     {'* '}ABUL KHAIR GROUP
                   </h6>
                   <h6 className='about-descrption'>
                   {'* '}AKIJ GROUP
                   </h6>
                   <h6 className='about-descrption'>
                   {'* '}BANGLADESH STEEL RE-ROLLING MILLS LTD.
                   </h6>
                   <h6 className='about-descrption'>
                   {'* '}RFL GROUP
                   </h6>
                   <h6 className='about-descrption'>
                   {'* '}ACI FOODS LTD. 
                   </h6>
                   <h6 className='about-descrption'>
                   {'* '}MERIDIAN GROUP 
                   </h6>
                   <h6 className='about-descrption'>
                   {'* '}PHP GROUP
                   </h6>
                   <h6 className='about-descrption'>
                   {'* '}SUNRISE INTERNATIONAL 
                   </h6>
                   <h6 className='about-descrption'>
                   {'* '}APEX FOOTWEAR LTD.
                   </h6>
                   <h6 className='about-descrption'>
                   {'* '}SH ENTERPRISE
                   </h6>

                   <h6 className='about-descrption'>
                     {'* '}IOE (BANGLADESH) LTD.
                   </h6>
                   <h6 className='about-descrption'>
                   {'* '}EUROPA CORPORATION
                   </h6>
                   <h6 className='about-descrption'>
                   {'* '}HIFS AGRO FOOD IND
                   </h6>
                   <h6 className='about-descrption'>
                   {'* '}REGENCY THREE LTD
                   </h6>
                   <h6 className='about-descrption'>
                   {'* '}ALIN FOODS TRADE
                   </h6>
                   <h6 className='about-descrption'>
                   {'* '}YOUNGONE GARMENT ACCESSORIES IND. LTD
                   </h6>
                   <h6 className='about-descrption'>
                   {'* '}KARNAPHULI SHOESINDUSTRIES LTD
                   </h6>
                  

                </div>

            </div>
          
         
            </div>
            <Footer/>
            </div>
        
    )
}

export default  SocialResponsibility;