
import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import UnCarousel from '../components/Carousel';
import Navbar from '../components/Navbar';
import BodyOneImg from '../images/body_one.jpeg';
import BodyTwoImg from '../images/body_two.jpeg';
import BodyThreeImg from '../images/body_three.jpeg';
import BodyFourImg from '../images/body_four.jpg';
import '../App.css'
import Footer from '../components/Footer';


function Home() {

  return (
    <div >
    <Navbar/>
    <UnCarousel/>
    <div>
      <div className='welcome'>
        <h3 className='text'>Welcome To INFINITE SHIPPING & LOGISTICS.</h3>
       
      </div>
      <div className='container home-body-list'>
        <div className='row'>
        
        <div className="col-md-3 col-sm-12">
          <div className="imagebox style1">
                            <div className="imagebox-image">
                <img src={BodyOneImg} alt=""/></div>
                            <div className="imagebox-header">
                <h3 className="imagebox-title"><a href="#" title="">Solutions</a></h3></div>
                            <div className="imagebox-content">
                                <div className="imagebox-desc">Our innovative solutions provide measurable logistics efficiencies throughout your supply chain.</div>
                            </div>
                        </div>
            </div>
            <div className="col-md-3 col-sm-12">
          <div className="imagebox style1">
                            <div className="imagebox-image">
                <img src={BodyTwoImg} alt=""/></div>
                            <div className="imagebox-header">
                <h3 className="imagebox-title"><a href="#" title="">Flexibility</a></h3></div>
                            <div className="imagebox-content">
                                <div className="imagebox-desc">Customized transportation programs are a hallmark of our service.</div>
                            </div>
                        </div>
            </div>
            <div className="col-md-3 col-sm-12">
          <div className="imagebox style1">
                            <div className="imagebox-image">
                <img src={BodyThreeImg} alt=""/></div>
                            <div className="imagebox-header">
                <h3 className="imagebox-title"><a href="#" title="">Expertise</a></h3></div>
                            <div className="imagebox-content">
                                <div className="imagebox-desc">Empowering international supply chains since 1974.</div>
                            </div>
                        </div>
            </div>
            <div className="col-md-3 col-sm-12">
          <div className="imagebox style1">
                            <div className="imagebox-image">
                <img src={BodyFourImg} alt="" style ={{widt:"249px", height:"248px"}}/></div>
                            <div className="imagebox-header">
                <h3 className="imagebox-title"><a href="#" title="">Service</a></h3></div>
                            <div className="imagebox-content">
                                <div className="imagebox-desc">Quality customer service is the foundation of our solid reputation.</div>
                            </div>
                        </div>
            </div>
        </div>


        

        

      </div>

      <div className='mission-vission'>

        <div className='container'>
        <div className='row'>
          <div className='col-sm-6'>
            <h1>Our Vision</h1>
            <p className='p-text w-100'>Infinite is focused on providing world class logistics service at a level always exceeding our competitors. We make an extra effort to serve our clients, to get to know them well and meet their needs and requirements. We will offer our clients not just a reactive service but also a proactive approach by using our knowledge to not only provide excellent service, but also to help guide our clients in the most effective direction as it pertains to transport logistics. infinite will always be committed to excellence, which can only be measured by the satisfaction of our clients.</p>

          </div>
         

          <div className='col-sm-6'>
          <h1>Our Mission</h1>
          <p className='p-text w-100'>Our Mission is to provide full service freight forwarder and customs broker. We are committed to provide the highest levels of service and quality assurance. Our diligence and reliability has earned a reputation for being a personal freight forwarder.</p>
        </div>

        </div>

        </div>
  
       
      </div>
    
    </div>
    <Footer/>
    </div>

   
  
  );
}

export default Home;