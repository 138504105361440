import Container from 'react-bootstrap/Container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";
import {faUsers} from "@fortawesome/free-solid-svg-icons";
import {faBuilding} from "@fortawesome/free-solid-svg-icons";
import facebook from '../images/facebook.svg';
import google from '../images/google.svg';
import twitter from '../images/twitter.svg';
import logo from '../images/inlogo.svg';
import styled from 'styled-components';
import './Footer.css';


function Footer() {
  return (

    <div>
      <div className='top-footer'>
        <div className='container'>
        <div className='row'>
          <div className='col-sm-6'>
            <h2 className='top-footer-text text-bold fw-bold'>Doing Express over 10 years of experience Logistics.</h2>
            <hr class="divider"/>

          </div>

          <div className='col-sm-2'>
          <FontAwesomeIcon className='fa-icon'  icon={faBriefcase} />
          <br/>
          <span class="numb-count text-bold fw-bold" data-from="0" data-to="50" data-speed="1000" data-waypoint-active="yes">50</span>
          <p>Brand Office</p>

          </div>

          <div className='col-sm-2'>
          <FontAwesomeIcon className='fa-icon'  icon={faUsers} />
          <br/>
          <span class="numb-count text-bold fw-bold" data-from="0" data-to="50" data-speed="1000" data-waypoint-active="yes">1987</span>
          <p>clients Happay</p>

          </div>

          <div className='col-sm-2'>
          <FontAwesomeIcon className='fa-icon'  icon={faBuilding} />
          <br/>
          <i class="fa fa-briefcase" aria-hidden="true"></i>
          <span class="numb-count text-bold fw-bold" data-from="0" data-to="50" data-speed="1000" data-waypoint-active="yes">40</span>
          <p>Consignment Completed</p>

          </div>



        </div>

        </div>
        

      </div>

 
<FooterContainer className='main-footer'>
      <div className='footer-middle'>
      <div className='container'>

        <div className='row'>
          <div className='col-md-4 col-sm-6'>
            <a  href={'/'}> <img src={logo} alt = "logo" style ={{widt:"100px", height:"50px"}}/></a>
            <p className="text-justify  text-left">Infinite is focused on providing world class logistics service at a level always exceeding our competitors. We make an extra effort to serve our clients, to get to know them well and meet their needs and requirements.</p>
           
           <div className='row'>
             <div className='col-md-1 col-sm-12'>
              <a href='#'>
              <img src={twitter} alt = "logo" style ={{widt:"35px", height:"35px"}}/>
              </a>
             </div>
             <div className='col-md-1 col-sm-12'>
              <a href='#'>
              <img src={facebook} alt = "logo" style ={{widt:"35px", height:"35px"}}/>
              </a>
             </div>
             <div className='col-md-1 col-sm-12'>
             <a href='#'>
              <img src={google} alt = "logo" style ={{widt:"35px", height:"35px"}}/>
              </a>
             </div>

           </div>
       

          </div>

          <div className='col-md-4 col-sm-6'>
            <h4>OUR SERVICE</h4>

            <div className='row'>
              <div className='col-md-6 col-sm-12'>

              <ul className="list-unstyled">
                <li><a  href={'/'}>{'>'} Home</a></li>
                <li><a  href={'/new-update'}>{'>'} New Update</a></li>
                <li><a  href={'/photogelley'}>{'>'} Photo Gellery</a></li>
                <li><a  href={'/sitemap'}>{'>'} Site Map</a></li>
              </ul>
            
                
              </div>

              <div className='col-md-6 col-sm-22'>

              <ul className="list-unstyled">
                <li><a  href={'/notice-board'}>{'>'} Notice Board</a></li>
                <li><a  href={'/download'}>{'>'} Improtant Downloads</a></li>
                <li><a  href={'/carrer'}>{'>'} Carrer</a></li>
              </ul>
             
              
             
              
              
              </div>


            </div>
            


          </div>
          <div className='col-md-4 col-sm-6'>
            <h4>CONTACT US</h4>

            <form>
  <div className="row">
    <div className="form-group col-md-6">
      
      <input type="text"   placeholder="Name"/>
    </div>
    <div className="form-group col-md-6">
    
      <input type="email"   placeholder="Email"/>
    </div>
 
  </div>
  <div className="form-group col-md-12 mt-2">
  
  <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" placeholder="Message"></textarea>
</div>
<button className="btn btn-primary mt-2" type="submit">Send Message</button>

            </form>
            


          </div>
         

        </div>


       <div className='footer-bottom'> 
       <p className='text-md-center'>
        &copy;2023 Infini app - All Right Reserved
       </p>


       </div>
      </div>
      </div>
    </FooterContainer>

    </div>
  
  );
}

export default Footer;

const FooterContainer = styled.footer`
.footer-middle{
  background: var(--mainDark);
  padding-top:3rem;
  color: var(--mainWhite);
}

.footer-bottom{
  padding-top:3rem;
  padding-bottom:3rem;
}

top_footer{
  color:var(--mainOrange)
}

ul li a{
  color: var(--mainWhite);
}

ul li a:hover{
  color: var(--mainOrange);
}
a:link, a:visited, a:hover, a:active{
  text-decoration: none;
}

`;