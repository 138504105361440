import React from 'react'
import { Button, Col, Row } from 'react-bootstrap';
import "./Nav.css"
import logo from '../images/inlogo.svg';
import { useNavigate,Link } from "react-router-dom";


 function Navbar() {
  const navigate = useNavigate();
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-secondary">
  <div className="container">
    <a className="navbar-brand text-white text-uppercase" href={'/'}> <img src={logo} alt = "logo" style ={{widt:"100px", height:"50px"}}/></a>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarNav">
      <ul className="navbar-nav">
      <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle text-white text-uppercase text-bold fw-bold ms-5" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="true">
            The Groupe
          </a>
          <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
            <li><a className="dropdown-item" href={'/about'}>About Us</a></li>
            <li><hr className="dropdown-divider"/></li>
            <li><a className="dropdown-item" href={'/message'}>Message From MD</a></li>
            <li><hr className="dropdown-divider"/></li>
            <li><a className="dropdown-item" href={'/mission'}>Our Mission & Vision</a></li>
            <li><hr className="dropdown-divider"/></li>
            <li><a className="dropdown-item" href={'/corporate'}>Corporate Values</a></li>
            <li><hr className="dropdown-divider"/></li>
            <li><a className="dropdown-item" href={'/board-directors'}>Board of Directors</a></li>
            <li><hr className="dropdown-divider"/></li>
            <li><a className="dropdown-item" href={'/activities'}>Our transports</a></li>
            <li><hr className="dropdown-divider"/></li>
            <li><a className="dropdown-item" href={'/social-responsibility'}>Our Clients</a></li>
            <li><hr className="dropdown-divider"/></li>
            <li><a className="dropdown-item" href={'/bangladesh-profile'}>Bangladesh Profile</a></li>
          </ul>
        </li>
        <li className="nav-item">
          <a className="nav-link text-white text-uppercase text-bold fw-bold ms-5" href={'/our-concern'}>Our Concerns</a>
        </li>
        <li className="nav-item">
          <a className="nav-link text-white text-uppercase fw-bold ms-5" href={'/global-markets'}>Global Market</a>
        </li>
       
        <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle text-white text-uppercase text-bold fw-bold ms-5" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="true">
            Product & Service
          </a>
          <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
            <li><a className="dropdown-item" href={'/our-products'}>Our Products</a></li>
            <li><hr className="dropdown-divider"/></li>
            <li><a className="dropdown-item" href={'/services'}>Our Services</a></li>
            <li>
        </li>

          </ul>
        </li>
        <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle text-white text-uppercase text-bold fw-bold ms-5" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="true">
            Media
          </a>
          <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
            <li><a className="dropdown-item" href={'/useful-link'}>Useful Link</a></li>
            <li><hr className="dropdown-divider"/></li>
            <li><a className="dropdown-item" href={'/photogelley'}>Photo Gellery</a></li>
          </ul>
        </li>
        <li className="nav-item">
          <a className="nav-link  text-white text-uppercase fw-bold ms-5" href={'/contact-us'}>Contact Us</a>
        </li>


      

        
      </ul>
    </div>
  </div>
</nav>
  )
}
export default Navbar
