import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import '../BangladeshProfile.css';

function Activities(){
    return(
        <div>
            <Navbar/>
            <div>
                <div className='about-title'>
                <div className='container'>
                <h1 className='text-bold fw-bold'>Our Transports</h1>

               
            </div>


                </div>

            
                <div className='container corporate-value'>
                
                <p className='port_sub_title'>
                   Air Transport 
               </p>

               <h6 className='board-director-one'>
                     {'* '}Competitive rates with major global carriers
                   </h6>
                   <h6 className='about-descrption'>
                   {'* '}Dedicated customer service
                   </h6>
                   <h6 className='about-descrption'>
                   {'* '}IATA licensed import & export air services
                   </h6>
                   <h6 className='about-descrption'>
                   {'* '}In-bond air / truck service
                   </h6>
                   <h6 className='about-descrption'>
                   {'* '}Air consolidation services, including GOH
                   </h6>
                   <h6 className='about-descrption'>
                   {'* '}Global chartering or split-chartering options
                   </h6>
                   <h6 className='about-descrption'>
                   {'* '}Fast track or deferred transit options
                   </h6>
                   <h6 className='about-descrption'>
                   {'* '}Wheels up 
                   </h6>
                   <h6 className='about-descrption'>
                   {'* '}Customs clearance & final delivery
                   </h6>
                  

                   <p className='port_sub_title'>
                   Land Transport
               </p>

               <h6 className='board-director-one'>
                     {'* '}Turnkey inland freight capabilities in all global locales
                   </h6>
                   <p className='about-descrption'>{' \u00A0  *' }Our efficient, reliable options include:</p>
                    <p className='about-descrption'>{' \u00A0 \u00A0 -' } FCL / container drayage</p>
                    <p className='about-descrption'>{' \u00A0 \u00A0 -' } Air freight</p>
                    <p className='about-descrption'>{' \u00A0 \u00A0 -' }Expedited trucking</p>
                    <p className='about-descrption'>{' \u00A0 \u00A0 -' } Refrigerated</p>
                    <p className='about-descrption'>{' \u00A0 \u00A0 -' } Dry van</p>
                    <p className='about-descrption'>{' \u00A0 \u00A0 -' }Oversized / overweight</p>
                    <p className='about-descrption'>{' \u00A0 \u00A0 -' }Project cargo</p>
                   <h6 className='about-descrption'>
                   {'* '}Highly competitive freight rates by road or rail
                   </h6>
                   <h6 className='about-descrption'>
                   {'* '}Asset based provider in the Arabian Gulf Countries & Middle East
                   </h6>
                
                   <p className='about-descrption'>{' \u00A0 \u00A0 -' } Full door-to-door service using our own terminals & vehicles</p>
                    <p className='about-descrption'>{' \u00A0 \u00A0 -' } Established network of approved partners</p>
                  
                    <h6 className='about-descrption'>
                   {'* '}Dedicated service between four key gateways in:
                   </h6>
                
                   <p className='about-descrption'>{' \u00A0 \u00A0 -' }  United Arab Emirates (UAE)</p>
                    <p className='about-descrption'>{' \u00A0 \u00A0 -' } Kuwait</p>
                    <p className='about-descrption'>{' \u00A0 \u00A0 -' } Saudi Arabia</p>
                    <p className='about-descrption'>{' \u00A0 \u00A0 -' } Lebanon</p>
                  
                   
                </div>

            </div>
          
            <Footer/>
            </div>
        
    )
}

export default  Activities;