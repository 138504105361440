import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import '../Contactus.css';

function ContactUs(){
    return(
        <div>
            <Navbar/>
            <div>
                <div className='about-title'>
                <div className='container'>
                <h1 className='text-bold fw-bold'>Contact Us</h1>

               
            </div>


                </div>

            
                <div className='container about-body'>
                    <h2 className='about-infinity-title'>INFINITE SHIPPING & LOGISTICS
TBA Cargo Service (C&F Agent)</h2>

                   

                    <p className='contact-us-title text-bold fw-bold'>
                        Head Office:
                    </p>

                    <p className='contact-us-text'>
                    Facy Building (2nd Floor), 87, Agrabad C/A, 

                    </p>
                    <p className='contact-us-text'>
                    Chittagong, Bangladesh. 

                    </p>
                
                    <p className='contact-us-text'>
                    Mobile: +8801972453673

                    </p>
                    


                    <p className='contact-us-title text-bold fw-bold'>
                        Dhaka Office:
                    </p>

                    <p className='contact-us-text'>
                    Hafizullah Green (9th Floor),
                    15/A, Zigatola,

                    </p>
                    <p className='contact-us-text'>
                    Dhanmondi, Dhaka,
                    Bangladesh.

                    </p>
                    <p className='contact-us-text'>
                    Mobile: +8801972453672

                    </p>

                    <p className='contact-us-title text-bold fw-bold'>
                        Contact With Person:
                    </p>

                    <p className='contact-us-text'>
                     Mr. Kamul Hasan, CEO<br/>
                     Contact no: +8801781585903<br/>
                     Email: khasan@infiniteslbd.com
                     </p>
                     <p className=''contact-us-text>
                     Mr. Mohammad Amin, Manager<br/>   
                     Contact No: +8801972453673<br/> 
                     Email: md.amin@infiniteslbd.com
                     </p>

                     <p className=''contact-us-text>
                     Mr. Shakya Mirta, Operation<br/>   
                     Contact No: +8801972453674 
                     </p>
                  
                     <p className=''contact-us-text>
                     Mr. Emon Sarkar, Operation<br/>   
                     Contact No: +8801815370736 
                     </p>
                     <p className=''contact-us-text>
                     Mr. Afsar, Custom Sarkar (C&F)<br/>   
                     Contact No: +8801961078226 
                     </p>

                     <p className=''contact-us-text>
                     Mr. Somir, Jetty Sarkar (C&F)<br/>   
                     Contact No: +8801680301721 
                     </p>
                  <p  className='employ-amount'>
                   26 Person (Chittagong & Dhaka Office)
                  </p>



                </div>

            </div>
          
            <Footer/>
            </div>
        
    )
}

export default  ContactUs;