import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import '../MdMessage.css';

function Message(){
    return(
        <div>
            <Navbar/>
            <div>
                <div className='about-title'>
                <div className='container'>
                <h1 className='text-bold fw-bold'>Message from MD</h1>

               
            </div>


                </div>

            
                <div className='container about-body'>
                    <h2 className='about-infinity-title'>Welcome to our website of Infinite Shipping & Logistics Limited</h2>

                    <p className='description'>
                    Welcome to our website of Infinite Shipping & Logistics Limited. which is a dedicated Shipping Agency company in the port city of Chittagong , Mongla as operational offices and Dhaka as the Marketing and Sales office .The company staffed by professionals and experienced hands in operational and sales. We began with modern facilities in consistent with the modern technology and keeping compliance issues to international standard. Due to present growing market demand we have the unique plan to set up my team in compliance with the requirements of client by introducing quality focused management. Our vision is to achieve the target of highest serving company in shipping company.</p>


                </div>

            </div>
          
            <Footer/>
            </div>
        
    )
}

export default  Message;