import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import '../BoardOfDirectors.css';

function OurServices(){
    return(
        <div>
            <Navbar/>
            <div>
                <div className='about-title'>
                <div className='container'>
                <h1 className='text-bold fw-bold'>Our Service</h1>

               
            </div>


                </div>

            
                <div className='container about-body'>
                    <h2 className='about-infinity-title'>Our Services</h2>

                    <h6 className='board-director-one'>
                     {'* '}Competitive rates with major global carriers
                   </h6>
                   <h6 className='about-descrption'>
                   {'* '}Dedicated customer service
                   </h6>
                   <h6 className='about-descrption'>
                   {'* '}IATA licensed import & export air services
                   </h6>
                   <h6 className='about-descrption'>
                   {'* '}In-bond air / truck service
                   </h6>
                   <h6 className='about-descrption'>
                   {'* '}Air consolidation services, including GOH
                   </h6>
                   <h6 className='about-descrption'>
                   {'* '}Global chartering or split-chartering options
                   </h6>
                   <h6 className='about-descrption'>
                   {'* '}Fast track or deferred transit options
                   </h6>
                   <h6 className='about-descrption'>
                   {'* '}Wheels up 
                   </h6>
                   <h6 className='about-descrption'>
                   {'* '}Customs clearance & final delivery
                   </h6>
                   <h6 className='about-descrption'>
                   {'* '}Door to Door Services.
                   </h6>

                   <p className='port_sub_title'>
                Less-Than Container Loads (LCL) Services:
               </p>

               <h6 className='aboard-director-one'>
                     {'* '}Cargo consolidation & deconsolidation
                   </h6>
                   <h6 className='about-descrption'>
                   {'* '}Vendor & P.O. management
                   </h6>
                   <h6 className='about-descrption'>
                   {'* '}Short- and long-term warehousing capabilities
                   </h6>
                   <h6 className='about-descrption'>
                   {'* '}Load planning & optimization
                   </h6>
                   <h6 className='about-descrption'>
                   {'* '}Value added services: milk runs, shrink wrap, bar-coding,maging, inspections, container stuffing
                   </h6>
                   <h6 className='about-descrption'>
                   {'* '}Dependable ocean freight transportation
                   </h6>
                   <h6 className='about-descrption'>
                   {'* '}Distribution to ramp or door destinations throughout the United States
                   </h6>
                   <h6 className='about-descrption'>
                   {'* '}Expedited services (sea-air, express truck, etc.) available
                   </h6>
                 

                   <p className='port_sub_title'>
                   P.O. & Vendor Management:
               </p>

               <h6 className='about-descrption'>
                   {'* '}Constant engagement & systematized methodology to manage all aspects of vendor transactions, including

                   </h6>
                    <p className='about-descrption'>{' \u00A0 \u00A0 -' } P.O. management (order quantities / discrepancies)</p>
                    <p className='about-descrption'>{' \u00A0 \u00A0 -' } Ready dates, early / late shipments</p>
                    <p className='about-descrption'>{' \u00A0 \u00A0 -' } Consolidation planning, bookings</p>
                    <p className='about-descrption'>{' \u00A0 \u00A0 -' } Loadability, Documents etc</p>

                   <h6 className='about-descrption'>
                   {'* '}Manage entire vendor compliance program; execute non-compliance penalties (late docs, ocean freightdifference, etc.)
                   </h6>
                   <h6 className='about-descrption'>
                   {'* '}Training at every relationship stage (on-board meetings,vendor workshops, compliance consults, etc.)
                   </h6>
                   <h6 className='about-descrption'>
                   {'* '}Cradle to grave customised reporting
                   </h6>

                   <p className='port_sub_title'>
                   Specialty Handling Services:
               </p>

               <h6 className='about-descrption'>
                   {'* '}Full service solutions for both dry and refrigerated cargo, including:

                   </h6>
                    <p className='about-descrption'>{' \u00A0 \u00A0 -' }Consolidation</p>
                    <p className='about-descrption'>{' \u00A0 \u00A0 -' } Transloading</p>
                    <p className='about-descrption'>{' \u00A0 \u00A0 -' } Labeling: multi-language available</p>
                    <p className='about-descrption'>{' \u00A0 \u00A0 -' } Container loading</p>
                    <p className='about-descrption'>{' \u00A0 \u00A0 -' } Crating and packing</p>

                   <h6 className='about-descrption'>
                   {'* '}On-site packing available for oversized, sensitive or heavy cargo
                   </h6>
                   <h6 className='about-descrption'>
                   {'* '} Heavy equipment / machinery
                   </h6>

                </div>

            </div>
          
            <Footer/>
            </div>
        
    )
}

export default  OurServices;