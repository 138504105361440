import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import '../CorporateValue.css';
import Network from '../images/network.png';

function CorporateValue(){
    return(
        <div>
            <Navbar/>
            <div>
                <div className='about-title'>
                <div className='container'>
                <h1 className='text-bold fw-bold'>Corporate Value</h1>

               
            </div>


                </div>

            
                <div className='container corporate-value'>
                    <h2 className='about-infinity-title'>Value Preposition</h2>

                    <h3 className='title'>
                        People + Network
                   </h3>

                   <h3 className='title'>
                        Network:
                   </h3>

                   <img src={Network} alt="" style ={{widt:"350px", height:"350px"}}/>
                   <table class="table table-striped">
  <thead>
    <tr>
      <th scope="col">Asia</th>
      <th scope="col">Middle Eas</th>
      <th scope="col">Europe</th>
      <th scope="col">The Americas</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th >Hong Kong</th>
      <td>Kuwait</td>
      <td>Germany</td>
      <td>Charlotte, NC USA</td>
    </tr>
    <tr>
      <th scope="row">Chiana</th>
      <td>Qatar</td>
      <td>Italy</td>
      <td>Columbus, OH USA</td>
    </tr>
    <tr>
      <th scope="row">Taiwan</th>
      <td>Saudi Aravia</td>
      <td>Turkey</td>
      <td>Dallas, TX USA</td>
    </tr>
    <tr>
      <th scope="row">Japan</th>
      <td>UAE</td>
      <td>-</td>
      <td>Houston, TX USA</td>
    </tr>
    <tr>
      <th scope="row">Vietnam</th>
      <td>Oman</td>
      <td>-</td>
      <td>Los Angeles, CA USA</td>
    </tr>
    <tr>
      <th scope="row">Malaysia</th>
      <td>-</td>
      <td>-</td>
      <td>Miami, FL USA</td>
      </tr>

      <tr>
      <th scope="row">Thailand</th>
      <td>-</td>
      <td>-</td>
      <td>New York, NY USA</td>
      </tr>


      <tr>
      <th scope="row">Indai</th>
      <td>-</td>
      <td>-</td>
      <td>Toronto, Canada</td>
      </tr>
      <tr>

      <th scope="row">-</th>
      <td>-</td>
      <td>-</td>
      <td>Ottawa, Canada</td>
      </tr>
      <tr>

      <th scope="row">-</th>
      <td>-</td>
      <td>-</td>
      <td>Vancouver, Canada</td>
      </tr>

      <tr>
      <th scope="row">-</th>
      <td>-</td>
      <td>-</td>
      <td>Montréal, Canada</td>
      </tr>

      <tr>
      <th scope="row">-</th>
      <td>-</td>
      <td>-</td>
      <td>São Paulo, Brazil</td>
      </tr>

      <tr>
      <th scope="row">-</th>
      <td>-</td>
      <td>-</td>
      <td>Santiago, Chile</td>
      </tr>
  </tbody>
</table>



                </div>

            </div>
          
            <Footer/>
            </div>
        
    )
}

export default  CorporateValue;