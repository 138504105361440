import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import '../BoardOfDirectors.css';

function BaordOfDirectors(){
    return(
        <div>
            <Navbar/>
            <div>
                <div className='about-title'>
                <div className='container'>
                <h1 className='text-bold fw-bold'>Board Of Directors</h1>

               
            </div>


                </div>

            
                <div className='container about-body'>
                    <h2 className='about-infinity-title'>Our Board Of Directors:</h2>

                    <h6 className='board-director-one'>
                    1. Mr. Kazi Kamrul Islam, Chairman
                   </h6>
                   <h6 className='about-descrption'>
                    2. Mr. Abul Hasan  Badal, Managing Partner   
                   </h6>
                   <h6 className='board-director-two'>
                    3. Mr. Tajul Islam, Partner
                   </h6>
                  
                </div>

            </div>
          
            <Footer/>
            </div>
        
    )
}

export default  BaordOfDirectors;